// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {

  production: false,
  // URL_SERVICE: 'http://150.136.57.80:8081/WSRestApiCoopedac/APIRest/', //Pruebas [IP Pública del servidor 35]


  // URL_SERVICE: 'http://localhost:8080/WSRestApiEstandarPortal/APIRest/', //Desarrollo
  URL_SERVICE: 'https://tswlocoopedacbe.ebsproductodigital.com/WSRestApiEstandarPortal/APIRest/', //Desarrollo

  // URL_SERVICE: 'http://190.85.106.122:8888/WSRestApiCoopedac/APIRest/', //Pruebas [IP Pública del servidor 35]
  //URL_SERVICE: 'http://localhost:8080/WSRestApiCoopedac/APIRest/', //Desarrollo
  // URL_SERVICE: 'http://10.10.30.35:8888/WSRestApiCoopedac/APIRest/',     //pruebas Oficina
  // URL_SERVICE: 'http://190.85.106.122:8888/WSRestApiCoopedac-v2/APIRest/', //Pruebas [IP Pública del servidor 35]

  // Productos del portal web
  CREDITO: '1',
  APORTES: '2',
  AHORRO_A_LA_VISTA: '3',
  RESERVAS: '4',
  AUXILIOS: '5',
  CDAT: '6', //5
  AHORRO_CONTRACTUAL: '7',
  CONSULTA_DE_SERVICIOS: '8',
  CONSULTA_DE_DEVOLUCIONES: '9',

  //url obtener ip
  URL_CONSULTAR_IP: 'https://api.ipify.org/?format=json'
};

// ng build --output-hashing=all --base-href /CoopedacTest/
// ng build --output-hashing=all --base-href /CoopedacTesting/

// COMPILAR PORTAL WEB
// ng build --base-href /PortalCoopedacTest/ --output-hashing=all

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
